var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-table',{ref:"table",staticClass:"position-relative",attrs:{"no-provider-sorting":true,"items":_vm.fetchData,"fields":_vm.columns,"current-page":_vm.currentPage,"per-page":_vm.perPage,"responsive":"","empty-text":"No Announcements","show-empty":""},scopedSlots:_vm._u([{key:"cell(media_file)",fn:function(ref){
var item = ref.item;
return [_c('b-avatar',{attrs:{"src":("" + _vm.$activitiesImagesURL + (item.media_file)),"text":_vm.avatarText(item.activity_name.slice(0,10)),"size":"10em","rounded":""}})]}},{key:"cell(interactions)",fn:function(ref){
var item = ref.item;
return [_c('b-row',[(item.enable_comments)?_c('b-col',{staticClass:"mb-1",attrs:{"lg":"4"}},[(item.enable_comments)?_c('b-link',{staticClass:"ml-2",attrs:{"disabled":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.comments_count)+" "),_c('feather-icon',{attrs:{"icon":"MessageSquareIcon"}})],1)]):_vm._e()],1):_vm._e(),(item.enable_likes)?_c('b-col',{staticClass:"mb-1",attrs:{"lg":"4"}},[(item.enable_likes)?_c('b-link',{staticClass:"ml-2",attrs:{"disabled":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.likes_count)+" "),_c('feather-icon',{attrs:{"icon":"ThumbsUpIcon"}})],1)]):_vm._e()],1):_vm._e(),(item.enable_share)?_c('b-col',{staticClass:"mb-1",attrs:{"lg":"4"}},[(item.enable_share)?_c('b-link',{staticClass:"ml-2",attrs:{"disabled":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.shares_count)+" "),_c('feather-icon',{attrs:{"icon":"Share2Icon"}})],1)]):_vm._e()],1):_vm._e()],1)]}},{key:"cell(enable_status)",fn:function(ref){
var item = ref.item;
return [(item.enable_status)?_c('span',{staticClass:"font-weight-bold"},[_c('feather-icon',{staticClass:"active-circle",attrs:{"icon":"CircleIcon"}}),_vm._v(" Active ")],1):_c('span',{staticClass:"font-weight-bold"},[_c('feather-icon',{attrs:{"icon":"CircleIcon"}}),_vm._v(" Inactive ")],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('table-actions',_vm._b({},'table-actions',{
          id:item.id,
          deleteEndPoint: _vm.deleteEndPoint,
          deleteRecord: _vm.deleteRecord,
          editModule:'edit-announce',
          viewModule:'view-announce'
        },false))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-row>
      <add-resource
        resource-name="ActivityController"
        add-route-name="add-announce"
      />
      <b-col>
        <b-card header="Search">
          <b-row class="mb-1">
            <b-col md="6">
              <search
                placeholder="Title"
                :search-query.sync="announcementsCriteria.searchQuery"
                @refresh="refreshTable"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <date-filter :date.sync="announcementsCriteria.date" />
            </b-col>

            <b-col md="4">
              <status-filter :status.sync="announcementsCriteria.enable_status" />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <b-row>
      <b-col>
        <announcements-table
          ref="parentTable"
          v-bind="{
            fetchData:getAnnouncements,
            currentPage:pagination.currentPage,
            perPage:pagination.perPage,
          }"
        />

        <pagination
          v-if="!!announcementsCriteria.selectedEntityId"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :current-page.sync="pagination.currentPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import AnnouncementsTable from '@/common/components/Announcements/AnnouncementsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import DateFilter from '@/common/components/common/Table/DateFilter.vue'
import StatusFilter from '@/common/components/Announcements/StatusFilter.vue'

export default {
  name: 'AnnouncementsList',
  components: {
    AddResource,
    StatusFilter,
    DateFilter,
    Search,
    SearchButton,
    AnnouncementsTable,
    Pagination,
  },
  data() {
    return {
      announcementsList: [],
      announcementsCriteria: {
        searchQuery: '',
        selectedEntityId: this.$store.getters['mainEntity/getEntityId'],
        date: new Date(),
        enable_status: 'active',
      },
      pagination: {
        totalRows: 10,
        currentPage: 1,
        perPage: 10,
      },
      loading: true,
    }
  },
  methods: {
    getAnnouncements() {
      return this.$activities.get('internalops/announcement-activities', {
        params: {
          entity: this.announcementsCriteria.selectedEntityId,
          page: this.pagination.currentPage,
          start_time: this.announcementsCriteria.date,
          status: this.announcementsCriteria.enable_status,
          name: this.announcementsCriteria.searchQuery,
        },
      })
        .then(res => {
          const announcements = res.data.data.data
          this.pagination.perPage = res.data.data.per_page
          this.pagination.totalRows = res.data.data.total
          return announcements || []
        })
        .catch(() => [])
        .finally(() => {
          this.loading = false
        })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">
.list-group {
    display: contents;
}
</style>

<template>
  <b-form-group
    label-for="blog-edit-category"
  >
    <v-select
      id="blog-edit-category"
      :value="status"
      :options="statusOption"
      :reduce="tag => tag.value"
      label="name"
      @input="onStatusChange"
    />
  </b-form-group>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'StatusFilter',
  components: {
    vSelect,
  },
  props: {
    status: { type: String, default: 'active' },
  },
  data() {
    return {
      statusOption: [
        {
          value: 'active',
          name: 'Active',
        },
        {
          value: 'inactive',
          name: 'Not Active',
        },
      ],
    }
  },
  methods: {
    onStatusChange(newStatus) {
      this.$emit('update:status', newStatus)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
